import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO title="聯絡我們" description="聯絡我們" />
      <section>
        <form
          name="contact"
          data-netlify="true"
          method="post"
          className="mx-auto md:w-1/2"
          action="contact-submission-success"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p className="mb-8 leading-loose">你可以透過以下表格聯絡我們。</p>
          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor="name"
          >
            姓名
          </label>

          <input
            className="w-full mb-6 form-input"
            id="name"
            placeholder="例如:陳小明"
            type="text"
            name="name"
          />

          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor="email"
          >
            電郵
          </label>

          <input
            className="w-full mb-6 form-input"
            id="email"
            placeholder="例如:abc@gmail.com"
            type="email"
            name="email"
          />

          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor="message"
          >
            信息
          </label>

          <textarea
            className="w-full mb-6 form-textarea"
            id="message"
            placeholder="例如:你好，請問..."
            rows="8"
            name="message"
          />

          <button
            type="submit"
            className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600"
          >
            發送信息
          </button>
        </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
